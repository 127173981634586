export const ENVIRONMENT_API_BASE_URL =
  process.env.NEXT_PUBLIC_ENVIRONMENT_API_BASE_URL ||
  'http://localhost:3000/env-api';

export const LPSLOGIN_API_KEY = process.env.NEXT_PUBLIC_LPSLOGIN_API_KEY || '';
export const LPSLOGIN_API_BASE_URL =
  process.env.NEXT_PUBLIC_LPSLOGIN_API_BASE_URL ||
  'http://localhost:3000/lpslogin-api';

export const PORTAL_URL = process.env.NEXT_PUBLIC_PORTAL_URL || '';

export const BASE_URL =
  process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:3000';

export const LINE_LOGIN_URL = process.env.NEXT_PUBLIC_LINE_LOGIN_URL || '';

export const MAX_LIST_RECORD_PER_PAGE = 20;

export const NAVBAR_HEIGHT = 69.75;
export const FOOTER_HEIGHT = 33;
export const SIDEBAR_SEARCH_DEBOUNCE_MS = 1000;

// Auth0 Constants
export const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || '';
export const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '';
export const AUTH0_AUDIENCE = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || '';
export const AUTH0_LOCAL_TOKEN =
  process.env.NEXT_PUBLIC_AUTH0_LOCAL_TOKEN || '';

export const COOKIE_DOMAIN =
  process.env.NEXT_PUBLIC_COOKIE_DOMAIN || 'localhost';

export const NOT_ALLOWED_URLS = [
  'r.lpslogin.dev.loopass.net',
  'r.lpslogin.test.loopass.net',
  'r.lpslogin.stg.loopass.net',
  'r.lpslogin.loopass.net',
];

export const WEBHOOK_URL_LIMIT = 5;
